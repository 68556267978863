import React, { useState } from 'react';
import { ReactComponent as LeafIcon } from '../../assets/icons/leaf.svg';
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import './home-page.section.research.scss';
import CarouselFade from '../../components/carousel-fade';

const researchBg = require('../../assets/images/research-bg-home.png');
const researchItem1 = require('../../assets/images/home-research-item-1.png');
const researchItem2 = require('../../assets/images/home-research-item-2.png');
const researchItem3 = require('../../assets/images/home-research-item-3.png');

interface Item {
    image: string;
    category: string;
    title: string;
    description: string;
}

const items: Item[] = [
    {
        image: researchItem1,
        category: 'FERTILIZATORI',
        title: 'Concepte De Recoltare',
        description: 'Introducerea soiurilor nou omologate și brevetate în parcele pilot și studiul comportării lor în condiții de producție'
    },
    {
        image: researchItem2,
        category: 'FRUCTE',
        title: 'Produse Recoltate',
        description: 'Secvențe tehnologice pentru obținerea materialului săditor pomicol'
    },
    {
        image: researchItem3,
        category: 'LEGUME',
        title: 'Fertilizarea Solului',
        description: 'Monitorizarea nutriției și fertilizarea în sistem ecologic în plantatiile pomicole'
    },
    {
        image: researchItem1,
        category: 'FERTILIZATORI',
        title: 'Concepts in Harvesting',
        description: 'Testing new approved crop varieties in pilot plots'
    },
    {
        image: researchItem2,
        category: 'FRUCTE',
        title: 'Harvested Products',
        description: 'Processes for producing orchard planting material'
    },
    {
        image: researchItem3,
        category: 'LEGUME',
        title: 'Soil Fertilization',
        description: 'Monitoring nutrition and fertilizing organic orchards'
    }
];

const ITEMS_PER_PAGE = 3;

const HomePageSectionResearch = () => {
    const carouselItems = items.map((item, index) => (
        <div className='item' key={index}>
            <div className='image-container'>
                <img src={item.image} alt={item.category} />
            </div>
            <div className='category'>{item.category}</div>
            <div className='title'>{item.title}</div>
            <div className='separator'></div>
            <span className='description'>{item.description}</span>
            <div className='arrow-container'>
                <ArrowIcon />
            </div>
        </div>
    ));
    return (
        <div className="homepage-research">
            <div className='bg-image-container'>
                <img src={researchBg} alt="Background" />
            </div>
                <CarouselFade
                    theme='dark'
                    helperText='Cercetare'
                    title='Proiecte Cercetare'
                    items={carouselItems}
                />
        </div>
    );
};

export default HomePageSectionResearch;
