import React, { useState } from 'react';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import './home-page.section.events.scss';
import CarouselFade from '../../components/carousel-fade';

const eventsItem1 = require('../../assets/images/home-events-item-1.png');
const eventsItem2 = require('../../assets/images/home-events-item-2.png');
const eventsItem3 = require('../../assets/images/home-events-item-3.png');

interface Item {
    image: string;
    category: string;
    title: string;
}

const items: Item[] = [
    {
        image: eventsItem1,
        category: 'BIO FOOD',
        title: 'Ce tehnologie este folosită în agricultura verticala?',
    },
    {
        image: eventsItem2,
        category: 'AGRICULTURA',
        title: 'Ce tip de agricultură este mai predominant astăzi?',
    },
    {
        image: eventsItem3,
        category: 'UTILAJE',
        title: 'Prezentarea echipamentelor și utilajelor',
    },
    {
        image: eventsItem3,
        category: 'UTILAJE',
        title: 'Prezentarea echipamentelor și utilajelor',
    },
    {
        image: eventsItem2,
        category: 'AGRICULTURA',
        title: 'Ce tip de agricultură este mai predominant astăzi?',
    },
    {
        image: eventsItem1,
        category: 'BIO FOOD',
        title: 'Ce tehnologie este folosită în agricultura verticala?',
    }
];


const HomePageSectionEvents = () => {
    const carouselItems = items.map((item, index) => (
        <div className='item' key={index}>
            <div className='image-container'>
                <img src={item.image} alt={item.category} />
                <div className='category'><span>{item.category}</span></div>
                <div className='arrow-container'>
                    <ArrowIcon />
                </div>
            </div>
            <div className='title'>{item.title}</div>

        </div>
    ));
    return (
        <div className="homepage-events">
            <CarouselFade
                theme='light'
                helperText='Ultimele Stiri'
                title='Evenimente'
                items={carouselItems}
            />
        </div>
    );
};

export default HomePageSectionEvents;
