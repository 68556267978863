import { ReactComponent as PhoneIcon } from '../assets/icons/contact-phone.svg';
import { ReactComponent as EmailIcon } from '../assets/icons/email.svg';
import { ReactComponent as LogoBaneasa } from '../assets/icons/logo-baneasa.svg';
import './app-footer.scss';

const categories = ['POMICULTURA', 'ORGANIC', 'AGRICULTURA', 'PRODUSE BIO']

const AppFooter = () => {
    return (
        <div className="app-footer">
            <div className='categories-contact-info-container'>
                <div className='categories'>
                    {categories.map((category, index) => (
                        <div key={index} className="category">
                            {category}
                            {index < categories.length - 1 && <div className="dot"></div>}
                        </div>
                    ))}
                </div>
                <div className='contact-info'>
                    <div className='phone'>
                        <div className='phone-icon'>
                            <PhoneIcon />
                        </div>
                        + 40 721 456 687
                    </div>
                    <div className='email'>
                        <div className='email-icon'>
                            <EmailIcon />
                        </div>
                        contact@sdcpbaneasa.ro
                    </div>
                </div>
            </div>
            <div className='details-container'>
                <div className='logo-info'>
                    <LogoBaneasa />
                    <div className='info'>
                        Baza experimentală Băneasa este situată în București în cadrul suprafețelor administrate de SCDP Băneasa.
                    </div>
                </div>
                <div className='content'>
                    <div className="description-links-container">
                        <p className="description">
                            În cadrul acestei unități sunt prezente atât suprafețe dedicate cercetării, cât și suprafețe destinate dezvoltării
                        </p>
                        <div className="footer-columns">
                            <div className="column">
                                <h4>Linkuri Utile</h4>
                                <ul>
                                    <li>Companie</li>
                                    <li>Despre noi</li>
                                    <li>Contact</li>
                                </ul>
                            </div>
                            <div className="column">
                                <h4>Program</h4>
                                <ul>
                                    <li>Mon - Fri: 9.00am - 5.00pm</li>
                                    <li>Saturday: 10.00am - 6.00pm</li>
                                    <li>Sunday Closed</li>
                                </ul>
                            </div>
                            <div className="column">
                                <h4>Adresa</h4>
                                <p>Bd. Ionescu de la Brad Ion, 4, Bucuresti-Sector 1, 013813.</p>
                            </div>
                        </div>
                    </div>
                    <div className='separator'></div>
                    <div className="footer-bottom">
                        <p>Termeni & Conditii | Politica de confidențialitate</p>
                        <p>Copyright © 2024 Baneasa. Toate drepturile rezervate</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppFooter;