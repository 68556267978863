import { useLocation } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '../assets/icons/arrow.svg';
import './header-info-section.scss';
import { useTranslation } from 'react-i18next';

const HeaderInfoSection = () => {
    const { t } =  useTranslation();
    const location = useLocation();
    const path = location.pathname.substring(1); 
    const isHome = path === 'home';
    return (
        <div className='info-section-container'>
        <div className="section">
            <div className='title-help-text'>{t(`header.${path}.badge-text`)}</div>
            <div className='title'>
            {t(`header.${path}.title`)}
            </div>
            <div className='separator'></div>
            <div className='subtitle'> {t(`header.${path}.description`)}.</div>
            { isHome &&
            <div className='details-container'>
                <span>Detalii</span>
                <ArrowIcon/>
            </div>
            }
        </div>
        </div>
    )
}

export default HeaderInfoSection;