import './home-page.section.news.scss';

const firstNewsImage = require('../../assets/images/new-first-image.png');
const secondtNewsImage = require('../../assets/images/new-second-image.png')

const HomePageSectionNews = () => {

    return (
        <div className="home-page-news-container">
            <div className='title'>
                Noutati
            </div>
            <div className='item'>
                <img src={firstNewsImage} alt="" />
                <div className='content'>
                    <div>Experții Organizației pentru Cooperare și Dezvoltare Economică (OCDE) au fost oaspeții Stațiunii de Cercetare Dezvoltare pentru Pomicultură Băneasa.</div>
                </div>
            </div>
            <div className='separator'></div>
            <div className='item'>
                <img src={secondtNewsImage} alt="" />
                <div className='content'>
                    <div>Primele activitati desfasurate la Statiunea de Cercetare si Dezvoltare Pomicola Baneasa nu au intarziat sa apara. A fost o zi plina si pentru cei mici dar si pentru cei mari. Un adevarat schimb de experienta!</div>
                </div>
            </div>
        </div>
    )
}

export default HomePageSectionNews