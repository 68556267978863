import './home-page.scss';
import HomePageSectionAboutus from './home-page.section.about-us';
import HomePageSectionLaboratories from './home-page.section.laboratories';
import HomePageSectionNews from './home-page.section.news';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import React from 'react';
import HomePageSectionResearch from './home-page.section.research';
import HomePageSectionEvents from './home-page.section.events';
import HomePageSectionBanner from './home-page.section.banner';

const HomePage = () => {
    const textItems = ['Agricultura', 'Pomicultura', 'Agricultura', 'Pomicultura', 'Agricultura', 'Pomicultura'];

    return (
        <div className="homepage-container">
            <HomePageSectionNews />
            <HomePageSectionLaboratories />
            <HomePageSectionAboutus />
            <div className="scrolling-text">
                <span>
                    {textItems.map((item, index) => (
                        <React.Fragment key={index}>
                            {item}
                            <StarIcon className="star-icon" />
                        </React.Fragment>
                    ))}
                </span>
            </div>
            <HomePageSectionResearch/>
            <HomePageSectionBanner/>
            <HomePageSectionEvents/>
        </div>
    );
}

export default HomePage;
