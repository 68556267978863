import { ReactComponent as LeafIcon } from '../../assets/icons/leaf.svg';
import { ReactComponent as FenceIcon } from '../../assets/icons/fence.svg';
import { ReactComponent as LawnIcon } from '../../assets/icons/lawn mower.svg';
import './home-page.section.about-us.scss';


const bg = require('../../assets/images/home-page-about-us-bg.png');

const HomePageSectionAboutus = () => {
    return (
        <div className="homepage-section-about-us">
            <img src={bg} alt="" />
            <div className='content'>
                <div className='title-help'>
                    <LeafIcon />
                    <span>Despre Noi</span>
                </div>
                <div className='title'>
                    Activități de cercetare de ameliorare în scopul obținerii de produse pomicole
                </div>
                <div className='subtitle'>
                    În cadrul laboratorului de ameliorare, au fost obținute o serie de soiuri valoroase la cais, piersic și nectarin
                </div>
                <div className='items-container'>
                    <div className='item'>
                        <FenceIcon />
                        <div className='title'>
                            Culturi ecologice
                        </div>
                        <div className='description'>
                            Introducerea in sortiment a unor soiuri de capsun performante cu potential productiv ridicat, rezistente la boli si daunatori.
                        </div>
                    </div>
                    <div className='item'>
                        <LawnIcon />
                        <div className='title'>
                            Echipamente speciale
                        </div>
                        <div className='description'>
                        Evaluarea caracteristicilor calitative la unele soiuri de cais in vederea imbunatatirii sortimentului
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HomePageSectionAboutus