import './app-header.scss';
import { ReactComponent as LogoBaneasa } from '../assets/icons/logo-baneasa.svg';
import { ReactComponent as PhoneIcon } from '../assets/icons/phone.svg';
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';
import { ReactComponent as ArrowIcon } from '../assets/icons/arrow.svg';
import HeaderInfoSection from './header-info-section';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const AppHeader = () => {
    const navigate = useNavigate();
    const [backgroundImage, setBackgroundImage] = useState();
    const location = useLocation();
    const path = location.pathname;
    const isHome = location.pathname === '/home';
    const handleNavigate = (path: string) => {
        navigate(path);
    }
    
    useEffect(() => {
        if(path !== '/')
             setBackgroundImage(require('../assets/images'+ path  + '-bg.png'))
      }, [path]);
    return (
        <header className={`app-header ${isHome ? 'home' : ''} `}  >
            <div className='overlay' style={{ background: `url(${backgroundImage})` }}>
                <div className='navbar-section'>
                    <div className='logo-container'>
                        <LogoBaneasa />
                    </div>
                    <div className='navbar-container'>
                            <div className='navigation'>
                                 <span onClick={() => handleNavigate('/home')}>ACASA</span>
                                 <div className='yellow-dot'></div>
                                 <span onClick={() => handleNavigate('/about-us')}>DESPRE NOI</span>
                                 <div className='yellow-dot'></div>
                                 <span onClick={() => handleNavigate('/home')}>CERCETARE</span>
                                 <div className='yellow-dot'></div>
                                 <span onClick={() => handleNavigate('/home')}>DEZVOLTARE</span>
                                 <div className='yellow-dot'></div>
                                 <span onClick={() => handleNavigate('/home')}>MAGAZIN</span>
                                 <div className='yellow-dot'></div>
                                 <span onClick={() => handleNavigate('/home')}>EVENIMENTE</span>
                                 <div className='yellow-dot'></div>
                                 <span onClick={() => handleNavigate('/home')}>SERVICII</span>
                                 <div className='yellow-dot'></div>
                                 <span onClick={() => handleNavigate('/home')}>ANUNTURI</span>
                            </div>
                            <div className='call-search-container'>
                                <PhoneIcon/>
                                <div className='call-text-container'>
                                    <span>Suna-ne!</span>
                                    <span>+40 721 567 890</span>
                                </div>
                                <div className='search'>
                                <SearchIcon/>
                                </div>
                            </div>
                    </div>
                    <div className='contact-us-container'>          
                        <div className='button-container'>
                            <span>Contact</span>
                            <ArrowIcon/>
                        </div>
                    </div>
                </div>
                    <HeaderInfoSection/>
            </div>
        </header>
    )
}
export default AppHeader;
