import { ReactComponent as HandIcon } from '../../assets/icons/lab-1.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import './home-page.section.banner.scss';

const bannerBg = require('../../assets/images/home-banner-bg.jpg');

const HomePageSectionBanner = () => {
    return (
        <div
            className="homepage-banner"
            style={{ backgroundImage: `url(${bannerBg})` }}>
            <div className="overlay">
                <div className="content">
                    <div className='icon-title-container'>
                        <HandIcon />
                        <span className='title'>Parte componentă a structurii organizaționale a cercetării agricole românești</span>
                    </div>
                    <div className='discover-container'>
                    <button className="cta-button">Descopera <ArrowIcon /></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePageSectionBanner;