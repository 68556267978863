import { Outlet } from "react-router-dom";
import './app-layout.scss';
import AppHeader from "./app-header";
import AppFooter from "./app-footer";

const AppLayout: React.FC = () => {
    return (
        <div className="app-layout">
            <AppHeader/>
            <Outlet/>
            <AppFooter/>
        </div>
    )
} 
export default AppLayout; 